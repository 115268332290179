<template>
  <div>
    <b-card>
      <template #header>
        Excel Parser
        <span class="float-right">
          <small class="mr-1">Auto-refresh in {{ (timer/1000) }} seconds.</small>
          <b-button class="mr-1" size="sm" variant="success" @click="() => $router.push('/excel-parsers/brands')">Brands</b-button>
          <b-button class="mr-1" size="sm" variant="secondary" @click="loadItems">Refresh</b-button>
          <b-button size="sm" variant="primary" @click="importModal = true">Import</b-button>
        </span>
      </template>
      <b-card-text>
        <div class="table">
          <b-table
          id="fba-shipments-table"
          class="border-bottom"
          show-empty
          :empty-text="tableLoading ? 'Getting Data...' : 'There are no records to show'"
          :busy="tableLoading"
          :items="items"
          :fields="fields"
          :per-page="pagination.perPage"
          :current-page="pagination.currentPage"
          hover>
            
            <template #cell(createdAt)="data">
              {{ data.item.createdAt }} <small v-if="data.item.timeFromNow"><em>({{ data.item.timeFromNow }})</em></small>
            </template>

            <template #cell(actions)="data">
              <b-dropdown split text="Download" size="sm" variant="primary" @click="onDownloadFile(data.item.resultFile)">
                <b-dropdown-item @click="onDownloadFile(data.item.originalFile)">Download Original File</b-dropdown-item>
                <b-dropdown-item @click="onDelete(data.item)">Delete</b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
          <b-pagination
          :disabled="tableLoading"
          v-if="items.length > 0"
          v-model="pagination.currentPage"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          aria-controls="fba-shipments-table"
          align="center"
          first-number
          last-number
          hide-goto-end-buttons
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>

    <b-modal v-model="importModal" title="Import" hide-footer no-close-on-backdrop @close="onImportCancel">
      <b-card-text>
        <b-form-group label="Select Parser:">
          <!-- <b-form-select v-model="form.brandName" :options="brandItems" @change="onBrandNameChange"></b-form-select> -->
          <vue-typeahead-bootstrap 
          v-model="form.brandName"
          :data="brandItems"
          :serializer="item => item.text"
          :showOnFocus="true"
          placeholder="Select Parser"
          :maxMatches="brandItems.length"
          @hit="onBrandNameChange"
          />
        </b-form-group>

        <b-form-group v-if="form.brandName" label="Download Able2Extract Template:">
          <b-link :href="form.template">Click Here to Download</b-link>
        </b-form-group>

        <b-form-group label="Upload File:">
          <b-form-file v-model="form.file" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></b-form-file>
        </b-form-group>

        <b-form-group class="mb-n1">
          <div class="controls float-right mt-2">
            <b-button :disabled="importLoading" class="mr-1" variant="secondary" @click="onImportCancel">Cancel</b-button>
            <b-button :disabled="importLoading" variant="primary" @click="onImportSubmit">
              <b-spinner v-if="importLoading" label="Spinning" small></b-spinner> Submit
            </b-button>
          </div>
        </b-form-group>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { defaultTimer } from '../constants';
import api from '../services/apiService'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

export default {
  components: {
    'vue-typeahead-bootstrap': VueTypeaheadBootstrap
  },
  data(){
    return {
      //import modal
      form: {
        brandName: "",
        template: "",
        file: null
      },
      importModal: false,
      importLoading: false,
      brandItems: [],

      //table
      items: [],
      fields: [
        {
          key: "createdAt",
          label: "Requested Date",
          class: 'align-middle'
        },
        {
          key: "requestor",
          label: "Requested By",
          class: 'align-middle'
        },
        {
          key: "batchId",
          label: "Batch ID",
          class: 'align-middle'
        },
        {
          key: "brandName",
          label: "Brand",
          class: 'align-middle'
        },
        {
          key: "requestType",
          label: "Type",
          class: 'align-middle'
        },
        {
          key: "actions",
          label: "Actions",
          class: 'align-middle'
        }
      ],
      tableLoading: false,
      pagination: {
        currentPage: 1,
        perPage: 25,
        totalRows: 0
      },
      timer: defaultTimer
    }
  },
  created(){
    this.loadBrandItems();
    this.loadItems();
    setInterval(() => {
      this.timer = this.timer - 1000
      if((this.timer/1000) === 0){
        this.loadItems()
        this.timer = defaultTimer
      }
    }, 1000)
  },
  methods: {
    onImportCancel(){
      this.importModal = false
      this.form = {
        brandName: "",
        template: "",
        file: null
      }
    },
    onDownloadFile(fileUrl){
      if(fileUrl){
        window.open(fileUrl)
      }else{
        this.$bvToast.toast("No file to download.", { title: `Error`, variant: 'danger', solid: true })
      }
    },
    async onImportSubmit(){
      this.importLoading = true
      try {
        let parserId = this.brandItems.find(e => e.text === this.form.brandName) ? this.brandItems.find(e => e.text === this.form.brandName).value : null
        if(!parserId){
          this.$bvToast.toast("Parser not found!", { title: `Error`, variant: 'danger', solid: true })
          this.importLoading = false
          return
        }
        
        let formData = new FormData()
        formData.append('parserId', parserId)
        formData.append('file', this.form.file)

        let response = await api.post(`/api/excel-parser/parse`, formData);
        this.$bvToast.toast(response.data.message, { title: `Success`, variant: 'success', solid: true })
        this.importLoading = false
        this.onImportCancel();
        await this.loadItems();
      } catch (error) {
        this.$bvToast.toast(error.data.message, { title: `Error`, variant: 'danger', solid: true })
        this.importLoading = false
      }
    },
    onBrandNameChange(data){
      this.form.template = this.brandItems.find(e => e.value === data.value).template
    },
    async loadBrandItems(){
      let response = await api.get('/api/excel-parser/brands')
      this.brandItems = [
        ...response.data.filter(e => e.templateLink && e.parserConfig).map(e => {
          return {
            value: e.id,
            text: `${e.brandName} - ${e.parserType}`,
            template: e.templateLink
          }
        })
      ]
    },
    async loadItems(){
      this.items = []
      this.tableLoading = true
      let response = await api.get('/api/excel-parser/result')
      this.items = response.data
      this.pagination = {
        ...this.pagination,
        totalRows: response.data.length
      }
      this.tableLoading = false
    },
    async onDelete(data){
      let isConfirmed = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this result?', { title: 'Confirm', buttonSize: 'sm', okTitle: 'Confirm', cancelTitle: 'Cancel', footerClass: 'p-2', hideHeaderClose: false })
      if(isConfirmed){
        let response = await api.delete(`/api/excel-parser/result/${data.id}`)
        this.$bvToast.toast(response.data.message, { title: 'Success', variant: 'success', solid: true })
        await this.loadItems()
      }
    },
  }
}
</script>